import { jsx as _jsx } from "react/jsx-runtime";
import { createRoot } from 'react-dom/client';
import { Outlet, RouterProvider, createBrowserRouter } from 'react-router-dom';
import SubtitlesPage from 'pages/SubtitlesPage';
import { ConfigProvider, App } from 'antd';
import { theme } from 'ant-config';
import EventsPage from 'pages/EventsPage';
import EventPage from 'pages/EventPage';
import { I18nProvider } from 'components/I18nProvider';
import './style.module.scss';
const LiveApp = () => {
    return (_jsx(I18nProvider, { children: _jsx(ConfigProvider, { theme: theme, children: _jsx(App, { children: _jsx(Outlet, {}) }) }) }));
};
const router = createBrowserRouter([
    {
        path: '/apps/live',
        element: _jsx(LiveApp, {}),
        children: [
            {
                path: '',
                element: _jsx(EventsPage, {}),
            },
            {
                path: 'spa/events/:eventId',
                element: _jsx(EventPage, {}),
            },
            {
                path: 'spa/subtitles/:eventId/:language?',
                element: _jsx(SubtitlesPage, {}),
            },
        ]
    },
]);
createRoot(document.querySelector('#app')).render(_jsx(RouterProvider, { router: router }));
