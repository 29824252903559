import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Card } from "antd";
import { CalendarOutlined, CloseCircleOutlined, Loading3QuartersOutlined } from "@ant-design/icons";
import { useI18n } from "components/I18nProvider";
import styles from './style.module.scss';
const renderIcon = (event) => {
    if (event === 'loading') {
        return _jsx(Loading3QuartersOutlined, { className: styles.icon, spin: true });
    }
    else if (event === 'not-found') {
        return _jsx(CloseCircleOutlined, { className: styles.icon });
    }
    else {
        return _jsx(CalendarOutlined, { className: styles.icon });
    }
};
const renderHeading = (event, intl) => {
    if (event === 'loading') {
        return intl.formatMessage({ id: 'loading' });
    }
    else if (event === 'not-found') {
        return intl.formatMessage({ id: 'eventMotFound' });
    }
    else {
        return event.title;
    }
};
const renderBody = (event, intl) => {
    if (event === 'loading') {
        return null;
    }
    else if (event === 'not-found') {
        return null;
    }
    else {
        return (_jsxs("div", { children: [_jsx("p", { children: event.description }), _jsx(Button, { target: "_blank", href: `/apps/live/spa/subtitles/${event.id}`, children: intl.formatMessage({ id: 'subtitlesPage' }) })] }));
    }
};
const EventCard = ({ event }) => {
    const { intl } = useI18n();
    return (_jsx(Card, { children: _jsxs("div", { className: styles.eventPanel, children: [renderIcon(event), _jsxs("div", { children: [_jsx("h2", { children: renderHeading(event, intl) }), renderBody(event, intl)] })] }) }));
};
export default EventCard;
