import { useState } from "react";
import { io } from "socket.io-client";
let recordingControl = 'ready';
const createTestingAudio = async (path, audioContext) => {
    const audioFile = await fetch(path);
    const arrayBuffer = await audioFile.arrayBuffer();
    const blob = new Blob([arrayBuffer], { type: "audio/wav" });
    const blobUrl = URL.createObjectURL(blob);
    const audio = new Audio();
    audio.src = blobUrl;
    audio.loop = true;
    const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
    const audioSource = audioContext.createBufferSource();
    audioSource.buffer = audioBuffer;
    audioSource.loop = true;
    return {
        source: audioSource,
        audioElement: audio,
    };
};
const createMicrophoneAudioSource = async (audioContext, deviceId) => {
    const stream = await navigator.mediaDevices.getUserMedia({ audio: { deviceId: { exact: deviceId } } });
    return audioContext.createMediaStreamSource(stream);
};
export const useRecording = () => {
    const [recordingState, setRecordingState] = useState({ type: 'ready' });
    const start = async (inputSource, eventId, segmentId) => {
        if (recordingControl !== 'ready') {
            throw new Error('Cannot start recording before stopping previous recording');
        }
        setRecordingState({ type: 'starting' });
        const socket = io('/s2tSession', {
            path: '/apps/live/socket.io',
            extraHeaders: {
                'Authorization': window.localStorage.authString,
            },
            query: {
                eventId,
                segmentId,
            },
        });
        const audioContext = new AudioContext({ sampleRate: 16000 });
        await audioContext.audioWorklet.addModule('js/record-processor.js');
        const audioProcessor = new AudioWorkletNode(audioContext, 'record-processor');
        let audioSource;
        let audioElement = null;
        if (inputSource.type === 'recording') {
            const testingAudio = await createTestingAudio(inputSource.path, audioContext);
            audioSource = testingAudio.source;
            audioElement = testingAudio.audioElement;
        }
        else {
            audioSource = await createMicrophoneAudioSource(audioContext, inputSource.deviceInfo.deviceId);
        }
        audioProcessor.port.onmessage = (e) => {
            if (e.data.eventType === 'initialized') {
                console.log('initialized');
                recordingControl = {
                    socket,
                    eventId,
                    segmentId,
                    inputSource,
                    audioSource,
                    audioContext,
                    audioElement,
                    muted: false,
                };
            }
            else if (e.data.eventType === 'frames') {
                if (recordingControl === 'ready') {
                    throw new Error('Cannot record data before initialization');
                }
                const frames = new Int16Array(e.data.buffer);
                console.log('sending audio');
                socket.emit('audio', {
                    chunk: recordingControl.muted ? frames.fill(0) : frames,
                });
            }
        };
        audioSource.connect(audioProcessor);
        if (inputSource.type === 'recording') {
            // TODO: Make this nicer
            audioSource.start();
            audioElement?.play();
        }
        setRecordingState({
            type: 'in-progress',
            muted: false,
            inputSource,
        });
        console.log('recording started', eventId, segmentId, audioSource, inputSource);
    };
    const mute = (state) => {
        if (recordingControl === 'ready') {
            throw new Error('Cannot mute before starting');
        }
        recordingControl.muted = state === 'on';
        setRecordingState({
            type: 'in-progress',
            muted: recordingControl.muted,
            inputSource: recordingControl.inputSource,
        });
    };
    const stop = () => {
        if (recordingControl === 'ready') {
            throw new Error('Cannot stop recording before starting');
        }
        recordingControl.audioSource.disconnect();
        recordingControl.audioContext.close();
        recordingControl.audioElement?.pause();
        recordingControl.socket.disconnect();
        recordingControl = 'ready';
        setRecordingState({ type: 'ready' });
    };
    if (recordingState.type === 'ready') {
        return { ...recordingState, start };
    }
    if (recordingState.type === 'starting') {
        return recordingState;
    }
    return {
        ...recordingState,
        mute,
        stop,
    };
};
