import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import { App, Button, Form, Input, Select } from 'antd';
import { startSegment } from 'api/segment';
import { useI18n } from 'components/I18nProvider';
import styles from './style.module.scss';
import { sortLocaleOptions } from 'lib/locales';
import { fetchTestRecordings } from 'api/testing';
const localeFilter = (input, option) => (option !== undefined && option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0);
const searchParams = new URLSearchParams(window.location.search);
const NewSegmentForm = ({ event, config, outputLanguages, segmentsCount, starting, onSubmit }) => {
    const [audioInputDevices, setAudioInputDevices] = useState('loading');
    const [testRecordings, setTestRecordings] = useState('loading');
    const [form] = Form.useForm();
    const engines = config.s2tEngines;
    const selectedEngine = Form.useWatch((values) => engines.find((engine) => engine.name === values.engine), form) ?? engines[0];
    const { intl } = useI18n();
    const { message } = App.useApp();
    const initialValues = {
        title: `Segment ${segmentsCount + 1}`,
        deviceId: null,
        testRecordingId: null,
        inputLanguage: selectedEngine.languages[0],
        outputLanguages: ['cs-CZ'],
        engine: engines[0].name,
    };
    useEffect(() => {
        const readAudioInputDevices = async () => {
            const devices = await navigator.mediaDevices.enumerateDevices();
            const audioDevices = devices.filter((device) => device.kind === 'audioinput');
            if (audioDevices.length === 0) {
                void message.error('No microphone found. Try again');
                return;
            }
            setAudioInputDevices(audioDevices);
            form.setFieldValue('deviceId', audioDevices[0].deviceId);
        };
        const loadTestRecordings = async () => {
            const recordings = await fetchTestRecordings();
            console.log('recordings', recordings);
            setTestRecordings(recordings);
            if (recordings.length !== 0) {
                form.setFieldValue('testRecordingId', recordings[0].id);
            }
        };
        loadTestRecordings();
        readAudioInputDevices();
    }, []);
    useEffect(() => {
        form.setFieldValue('inputLanguage', selectedEngine.languages[0]);
    }, [selectedEngine]);
    const handleSubmit = async (values) => {
        if (testRecordings === 'loading' || audioInputDevices === 'loading') {
            return;
        }
        const segment = await startSegment(event.id, {
            segmentTitle: values.title,
            inputLang: values.inputLanguage,
            outputLangs: values.outputLanguages,
            engine: values.engine,
        });
        const inputSource = (() => {
            if (searchParams.has('testing')) {
                const testRecording = testRecordings.find((recording) => recording.id === values.testRecordingId);
                return {
                    type: 'recording',
                    path: testRecording.path,
                    title: testRecording.title,
                };
            }
            return {
                type: 'mic',
                deviceInfo: audioInputDevices.find((device) => device.deviceId === values.deviceId)
            };
        })();
        onSubmit(inputSource, segment);
    };
    const prepareLocaleOptions = (locales) => sortLocaleOptions(locales.map((lang) => ({ label: intl.formatMessage({ id: lang }), value: lang })));
    return (_jsxs(Form, { labelWrap: true, layout: 'vertical', className: styles.form, initialValues: initialValues, onFinish: handleSubmit, disabled: starting, form: form, children: [_jsx(Form.Item, { name: "title", label: intl.formatMessage({ id: 'segmentTitle' }), children: _jsx(Input, {}) }), !searchParams.has('testing') && (_jsx(Form.Item, { name: "deviceId", label: intl.formatMessage({ id: 'microphone' }), children: _jsx(Select, { loading: audioInputDevices === 'loading', options: audioInputDevices === 'loading' ? [] : audioInputDevices.map((device) => ({ label: device.label, value: device.deviceId })) }) })), searchParams.has('testing') && (_jsx(Form.Item, { name: "testRecordingId", label: intl.formatMessage({ id: 'testRecording' }), children: _jsx(Select, { loading: testRecordings === 'loading', options: testRecordings === 'loading' ? [] : testRecordings.map((recording) => ({ label: recording.title, value: recording.id })) }) })), _jsx(Form.Item, { name: "engine", label: intl.formatMessage({ id: 'engine' }), children: _jsx(Select, { style: { width: '100%' }, placeholder: "Please select", options: engines.map((engine) => ({ label: engine.name, value: engine.name })) }) }), _jsx(Form.Item, { name: "inputLanguage", label: intl.formatMessage({ id: 'audioLanguage' }), children: _jsx(Select, { style: { width: '100%' }, placeholder: "Please select", filterOption: localeFilter, options: prepareLocaleOptions(selectedEngine.languages) }) }), _jsx(Form.Item, { name: "outputLanguages", label: intl.formatMessage({ id: 'outputLanguages' }), children: _jsx(Select, { mode: "multiple", style: { width: '100%' }, placeholder: "Please select", filterOption: localeFilter, options: prepareLocaleOptions(outputLanguages) }) }), _jsx(Form.Item, { className: styles.controls, children: _jsx(Button, { size: 'large', className: styles.controlsButton, loading: starting, type: "primary", htmlType: "submit", children: intl.formatMessage({ id: 'startRecording' }) }) })] }));
};
export default NewSegmentForm;
