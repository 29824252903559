import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { AudioOutlined, AudioMutedOutlined } from "@ant-design/icons";
import { App, Button, Card, Space } from "antd";
import NewSegmentForm from "../NewSegmentForm";
import { forceStopSegment } from "api/segment";
import { useRecording } from "lib/recording";
import { fetchConfig } from "api/config";
import { useI18n } from "components/I18nProvider";
import styles from './style.module.scss';
const RecordingCard = ({ event, activeSegment, segmentsCount, onSegmentStarted, onSegmentEnded }) => {
    const recording = useRecording();
    const { message } = App.useApp();
    const { intl } = useI18n();
    const [config, setConfig] = useState('loading');
    const [currentSegment, setCurrentSegment] = useState(activeSegment);
    useEffect(() => {
        // const handleWsError = (e: { message: string }) => {
        //   console.log(e.message);
        //   void message.error('Connection failed. Start a new segment.');
        //   if (recording.type === 'in-progress') {
        //     recording.stop();
        //   }
        //   setCurrentSegment('new');
        // }
        const getMicrophoneConsent = async () => {
            try {
                await navigator.mediaDevices.getUserMedia({ audio: true });
            }
            catch (error) {
                void message.error('Microphone access was denied');
            }
        };
        const fetchConfigState = async () => {
            setConfig(await fetchConfig());
        };
        fetchConfigState();
        getMicrophoneConsent();
    }, []);
    useEffect(() => {
        if (currentSegment !== null && recording.type === 'in-progress') {
            const preventUnload = (event) => {
                event.preventDefault();
            };
            window.addEventListener('beforeunload', preventUnload);
            return () => {
                window.removeEventListener('beforeunload', preventUnload);
            };
        }
    }, [currentSegment, recording.type]);
    const handleSubmit = async (inputSource, segment) => {
        if (recording.type === 'ready') {
            recording.start(inputSource, segment.eventId, segment.id);
        }
        setCurrentSegment(segment);
        onSegmentStarted(segment);
    };
    const endSegment = async () => {
        if (currentSegment === null) {
            return;
        }
        if (recording.type === 'in-progress') {
            recording.stop();
        }
        else {
            await forceStopSegment(event.id, currentSegment.id);
        }
        setCurrentSegment(null);
        onSegmentEnded();
    };
    const muteRecording = async () => {
        if (currentSegment === null) {
            return;
        }
        if (recording.type !== 'in-progress') {
            return;
        }
        recording.mute(recording.muted ? 'off' : 'on');
    };
    if (config === 'loading') {
        return _jsx(Card, { title: intl.formatMessage({ id: 'newSegment' }), loading: true });
    }
    return (_jsx(Card, { title: intl.formatMessage({ id: 'newSegment' }), children: currentSegment === null || recording.type === 'starting' ? (_jsx(NewSegmentForm, { event: event, config: config, outputLanguages: config.outputLanguages, segmentsCount: segmentsCount, starting: recording.type === 'starting', onSubmit: handleSubmit })) : (recording.type === 'ready' ? (_jsxs("div", { children: [_jsxs("p", { children: ["The segment ", _jsx("strong", { children: currentSegment.title }), " is still in progress!"] }), _jsx("p", { children: "Are you recording in another tab or device? You cannot start a new segment until the current one is ended." }), _jsx(Button, { onClick: endSegment, danger: true, children: "Force stop segment" })] })) : (_jsxs(_Fragment, { children: [_jsxs("div", { className: styles.recordingHeader, children: [_jsx("h2", { className: styles.title, children: intl.formatMessage({ id: 'segmentStart' }) }), recording.type === 'in-progress' && recording.muted
                            ? (_jsxs(Space, { children: [_jsx(AudioMutedOutlined, {}), _jsx("p", { className: styles.recordingText, children: intl.formatMessage({ id: 'muted' }) })] })) : (_jsxs(Space, { children: [_jsx(AudioOutlined, {}), _jsx("p", { className: styles.recordingText, children: intl.formatMessage({ id: 'recording' }) })] }))] }), _jsxs("div", { className: styles.infoGrid, children: [_jsxs("strong", { children: [intl.formatMessage({ id: 'engine' }), ': '] }), _jsx("span", { children: currentSegment.engine }), _jsx("strong", { children: "Zdroj:" }), _jsx("span", { children: recording.inputSource.type === 'mic'
                                ? `MIC: ${recording.inputSource.deviceInfo.label}`
                                : `TEST: ${recording.inputSource.title}` })] }), _jsxs("div", { className: styles.controls, children: [_jsx(Button, { onClick: endSegment, danger: true, children: intl.formatMessage({ id: 'endSegment' }) }), _jsx(Button, { onClick: muteRecording, children: intl.formatMessage({ id: recording.type === 'in-progress' && recording.muted ? 'unmute' : 'mute' }) })] })] }))) }));
};
export default RecordingCard;
