import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import clsx from "clsx";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useI18n } from "components/I18nProvider";
import SubtitlesHeader from "./SubtitlesHeader";
import SubtitlesPanel from "./SubtitlesPanel";
import SubtitlesFooter from "./SubtitlesFooter";
import { io } from "socket.io-client";
import styles from './style.module.scss';
import { loadSubtitlesSettings, storeSubtitlesSettings } from "./subtitles-settings";
import { IdleTimer } from "./idleTimer";
import AlertPanel from "./AlertPanel";
import { AudioMutedOutlined, CloseCircleFilled, Loading3QuartersOutlined } from "@ant-design/icons";
import { fetchPublicEvent } from "api/events";
const idleTimer = new IdleTimer();
const getSubtitlesLanguage = (suggestedLanguage, segment) => {
    const language = suggestedLanguage ?? segment.inputLang;
    const result = segment.outputLangs.find((lang) => lang === language);
    return result ?? segment.outputLangs[0];
};
const SubtitlesPage = () => {
    const navigate = useNavigate();
    const { eventId, language } = useParams();
    const [searchParams] = useSearchParams();
    const socketRef = useRef();
    const [event, setEvent] = useState('loading');
    const [segment, setSegment] = useState('loading');
    const [subtitlesSettings, setSubtitlesSettings] = useState(loadSubtitlesSettings);
    const [displayedText, setDisplayedText] = useState('');
    const [showControls, setShowControls] = useState(true);
    const { intl } = useI18n();
    useEffect(() => {
        idleTimer.onIdle(() => {
            setShowControls(false);
        });
        idleTimer.onActive(() => {
            setShowControls(true);
        });
        idleTimer.mount();
        return () => {
            idleTimer.unmount();
        };
    }, []);
    const initEvent = async () => {
        if (eventId === undefined) {
            return;
        }
        const event = await fetchPublicEvent(eventId);
        setEvent(event);
        const socket = io('/subtitles', {
            path: '/apps/live/socket.io',
            query: { eventId },
        });
        socket.on('activeSegment', (activeSegment) => {
            setSegment(activeSegment);
            if (activeSegment === 'none-active') {
                setDisplayedText('');
            }
            else {
                const subtitlesLanguage = getSubtitlesLanguage(language, activeSegment);
                socket.emit('selectLanguage', {
                    language: subtitlesLanguage,
                });
                if (language !== subtitlesLanguage) {
                    const languageParam = subtitlesLanguage === activeSegment.inputLang ? '' : `/${subtitlesLanguage}`;
                    navigate(`/apps/live/spa/subtitles/${eventId}${languageParam}`, { replace: true });
                }
            }
        });
        socket.on('words', (fragment) => {
            setDisplayedText((prev) => prev + fragment);
        });
        socketRef.current = socket;
    };
    useEffect(() => {
        initEvent();
    }, []);
    const goFullScreen = async () => {
        try {
            await document.documentElement.requestFullscreen();
        }
        catch (error) {
            console.error('Native fullscreen failed, using css as fallback.');
        }
    };
    const exitFullScreen = () => {
        if (document.exitFullscreen) {
            document.exitFullscreen();
        }
    };
    const handleSettingsChange = async (changedValues) => {
        const newSettings = {
            ...subtitlesSettings,
            ...changedValues,
        };
        setSubtitlesSettings(newSettings);
        storeSubtitlesSettings(newSettings);
        if ('fullscreen' in changedValues) {
            if (changedValues.fullscreen) {
                void goFullScreen();
            }
            else {
                exitFullScreen();
            }
        }
    };
    const handleLanguageChange = (language) => {
        if (segment === 'loading' || segment === 'none-active') {
            return;
        }
        socketRef.current?.emit('selectLanguage', { language });
        const languageParam = language === segment.inputLang ? '' : `/${language}`;
        navigate(`/apps/live/spa/subtitles/${eventId}${languageParam}`, { replace: true });
    };
    let panel = null;
    if (segment === 'loading' || event === 'loading') {
        panel = (_jsx(AlertPanel, { icon: _jsx(Loading3QuartersOutlined, { spin: true }), title: intl.formatMessage({ id: 'loading' }), size: "small" }));
    }
    else if (event === 'not-found') {
        panel = (_jsx(AlertPanel, { icon: _jsx(CloseCircleFilled, {}), title: intl.formatMessage({ id: 'eventNotFound' }), message: intl.formatMessage({ id: 'eventNotFoundDesc' }) }));
    }
    else if (segment === 'none-active') {
        panel = (_jsx(AlertPanel, { icon: _jsx(AudioMutedOutlined, {}), title: intl.formatMessage({ id: 'noActiveSegment' }), message: intl.formatMessage({ id: 'noActiveSegmentDesc' }) }));
    }
    else {
        const lines = displayedText.split('\n');
        panel = _jsx(SubtitlesPanel, { lines: lines, settings: subtitlesSettings });
    }
    return (_jsxs("div", { "data-theme": subtitlesSettings.theme, className: clsx(styles.subtitlesPage, { [styles.noControls]: !showControls }, { [styles.noUserSelect]: searchParams.get('autoScroll') !== 'off' }), children: [_jsx(SubtitlesHeader, {}), panel, segment === 'loading' || segment === 'none-active' ? _jsx("footer", {}) : (_jsx(SubtitlesFooter, { settings: subtitlesSettings, language: getSubtitlesLanguage(language, segment), onSettingsChange: handleSettingsChange, onLanguageChange: handleLanguageChange, segment: segment }))] }));
};
export default SubtitlesPage;
