export const theme = {
    cssVar: true,
    token: {
        // PRIMARY COLORS
        colorPrimary: '#ffae43',
        colorLink: '#ffae43',
        colorPrimaryHover: '#ffbb62',
        colorPrimaryActive: 'var(--ant-color-primary-hover)',
        colorPrimaryTextActive: 'var(--ant-color-primary-hover)',
        colorLinkHover: 'var(--ant-color-primary-hover)',
        colorLinkActive: 'var(--ant-color-primary-hover)',
        // SECONDARY CONTROLS COLOR: Selected menu, circle buttons
        colorPrimaryBorder: '#ffdaa9',
        colorPrimaryBorderHover: '#ffcf8f',
        // ACCENT COLORS & INFO: Switches, radio, checkbox, tags, badges, progress
        colorInfo: '#90d7ff',
        colorInfoHover: '#b9e6ff',
        colorInfoActive: 'var(--ant-color-info-hover)',
        colorInfoTextActive: 'var(--ant-color-info-hover)',
        colorInfoBg: '#f1faff', // Table row hover
        // SEMANTIC COLORS
        colorSuccess: '#9bd920',
        colorSuccessActive: '#d0f272',
        colorSuccessTextActive: 'var(--ant-color-success-active)',
        colorWarning: '#FFCA00',
        colorWarningActive: '#FFE852',
        colorWarningTextActive: 'var(--ant-color-warning-active)',
        colorError: '#ff7d4b',
        colorErrorActive: '#ff9f73',
        colorErrorTextActive: 'var(--ant-color-error-active)',
        // TEXT and ICON COLORS
        colorTextBase: '#444444',
        colorText: 'var(--ant-color-text-base)',
        colorTextSecondary: '#666666',
        colorTextDisabled: '#bababa',
        colorTextDescription: 'var(--ant-color-text-disabled)',
        colorIcon: 'var(--ant-color-text-base)',
        // NEUTRALS
        colorBorder: '#D9D9D9',
        colorBgContainerDisabled: '#eeeeee',
        colorFillTertiary: '#f6f6f6', // light bg gray
        // FONTS
        fontFamily: "'Open Sans', sans-serif",
        fontSize: 15,
        fontSizeSM: 14,
        fontSizeLG: 17,
        fontSizeHeading1: 23,
        fontSizeHeading2: 18,
        fontSizeHeading3: 16,
        fontSizeHeading4: 15,
        fontWeightStrong: 600,
        // OTHER
        borderRadius: 16,
        borderRadiusSM: 4,
        borderRadiusLG: 20,
        boxShadow: '0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05)',
        marginXXS: 0,
    },
    components: {
        Button: {
            borderRadiusSM: 40,
            borderRadius: 40,
            borderColorDisabled: 'transparent',
            colorLink: 'var(--ant-color-primary)',
            colorLinkHover: 'var(--ant-color-primary-hover)',
        },
        Modal: {
            titleFontSize: 17,
            contentPadding: '16px 30px',
        },
        Menu: {
            itemHoverBg: 'transparent',
            itemActiveBg: 'transparent',
            itemBg: 'transparent',
            itemMarginInline: 0,
            subMenuItemBg: 'transparent',
            activeBarBorderWidth: 0,
            itemPaddingInline: 0,
        },
        Layout: {
            siderBg: 'transparent',
            bodyBg: 'linear-gradient(180deg, #fdfaf6 0%, #f7f0e4 56%, #ffe9cb 100%)',
            headerBg: 'transparent',
            headerPadding: '0 20px',
            headerColor: 'var(--ant-color-text-base)',
            footerBg: 'transparent',
        },
        Table: {
            cellPaddingBlock: 5,
            cellPaddingInline: 5,
            headerColor: 'var(--ant-color-text-base)',
            headerFilterHoverBg: 'transparent',
            headerSplitColor: 'transparent',
            headerBg: 'var(--ant-color-fill-tertiary)',
            rowHoverBg: 'var(--ant-color-info-bg)',
            headerIconColor: 'var(--ant-color-text-secondary)',
        },
        Tag: {
            colorBorder: 'transparent',
            borderRadiusSM: 40,
        },
        Card: {
            paddingLg: '24px',
        },
        Badge: {
            colorError: 'var(--ant-color-info)',
            colorErrorHover: 'var(--ant-color-info-hover)',
        },
        Progress: {
            defaultColor: 'var(--ant-color-info)',
        },
        Switch: {
            colorPrimary: 'var(--ant-color-info)',
            colorPrimaryBorder: 'var(--ant-color-info)',
            colorPrimaryHover: 'var(--ant-color-info-hover)',
        },
        Radio: {
            radioBgColor: 'var(--ant-color-info)',
            buttonSolidCheckedHoverBg: 'var(--ant-color-info-hover)',
            buttonSolidCheckedBg: 'var(--ant-color-info)',
        },
        Slider: {
            trackBg: 'var(--ant-color-info)',
            handleActiveColor: 'var(--ant-color-info-hover)',
            handleColor: 'var(--ant-color-info)',
            dotActiveBorderColor: 'var(--ant-color-info-hover)',
            colorPrimaryBorderHover: 'var(--ant-color-info-hover)',
        },
        Alert: {
            colorErrorBorder: 'transparent',
            colorSuccessBorder: 'transparent',
            colorInfoBorder: 'transparent',
            colorWarningBorder: 'transparent',
        },
        Form: {
            itemMarginBottom: 15,
            labelFontSize: 15,
            verticalLabelPadding: 4,
        },
        Input: {
            activeBg: 'white',
            hoverBg: 'white',
        },
        Collapse: {
            contentBg: 'white',
            headerBg: 'white',
            headerPadding: 0,
            contentPadding: 0,
        },
        Select: {
            borderRadiusSM: 14,
        },
        Upload: {
            actionsColor: 'var(--ant-color-icon)',
        },
    },
};
