import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Select, Dropdown } from "antd";
import { FontSizeOutlined, FullscreenExitOutlined, FullscreenOutlined, MoonOutlined, SunOutlined } from "@ant-design/icons";
import { useI18n } from "components/I18nProvider";
import { fontSizes, isFontSize } from "../subtitles-settings";
import styles from './style.module.scss';
const SubtitlesFooter = ({ settings, language, onSettingsChange, onLanguageChange, segment }) => {
    const { intl } = useI18n();
    const handleFontSizeChange = (item) => {
        onSettingsChange({ fontSize: isFontSize(item.key) ? item.key : 'lg' });
    };
    const handleSwitchTheme = () => {
        const newTheme = settings.theme === 'dark' ? 'light' : 'dark';
        onSettingsChange({ theme: newTheme });
    };
    const handleSwitchFullscreen = () => {
        onSettingsChange({ fullscreen: !settings.fullscreen });
    };
    const items = Object.keys(fontSizes).map((key) => ({ key, label: intl.formatMessage({ id: `${key}FontSize` }) }));
    return (_jsxs("div", { className: styles.subtitlesFooter, children: [_jsx("div", { className: styles.toolbar, children: _jsx(Select, { className: styles.langSelect, popupClassName: styles.langSelectPopup, getPopupContainer: (triggerNode) => triggerNode.parentElement, value: language, onChange: onLanguageChange, options: segment.outputLangs.map((language) => ({
                        key: language,
                        value: language,
                        label: language === segment.inputLang
                            ? `${intl.formatMessage({ id: language })} (${intl.formatMessage({ id: 'original' })})`
                            : `${intl.formatMessage({ id: language })} (${intl.formatMessage({ id: 'translated' })})`,
                        className: styles.langSelectOption,
                    })) }) }), _jsxs("div", { className: styles.toolbar, children: [_jsx(Dropdown, { overlayClassName: styles.fontSize, menu: {
                            items,
                            selectable: true,
                            selectedKeys: [settings.fontSize],
                            onSelect: handleFontSizeChange,
                        }, getPopupContainer: (triggerNode) => triggerNode.parentElement, trigger: ["click"], children: _jsx("button", { className: styles.toolbarButton, children: _jsx(FontSizeOutlined, {}) }) }), _jsx("button", { className: styles.toolbarButton, onClick: handleSwitchTheme, children: settings.theme === 'dark' ? _jsx(SunOutlined, {}) : _jsx(MoonOutlined, {}) }), _jsx("button", { className: styles.toolbarButton, onClick: handleSwitchFullscreen, children: settings.fullscreen ? _jsx(FullscreenExitOutlined, {}) : _jsx(FullscreenOutlined, {}) })] })] }));
};
export default SubtitlesFooter;
