import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { fetchEvent } from 'api/events';
import { useParams } from 'react-router-dom';
import { Spin } from "antd";
import RecordingCard from "./RecordingCard";
import { fetchSegments } from 'api/segment';
import EventCard from './EventCard';
import Page from 'components/Page';
import SegmentsCard from './SegmentsCard';
import styles from './style.module.scss';
const EventPage = () => {
    const { eventId } = useParams();
    const [event, setEvent] = useState('loading');
    const [segments, setSegments] = useState('loading');
    const fetchSegmentsState = async () => {
        if (eventId === undefined) {
            return;
        }
        const segments = await fetchSegments(eventId);
        setSegments(segments);
    };
    useEffect(() => {
        const fetchEventState = async () => {
            if (eventId === undefined) {
                return;
            }
            const event = await fetchEvent(eventId);
            setEvent(event);
        };
        fetchEventState();
        fetchSegmentsState();
    }, []);
    const handleSegmentStarted = (segment) => {
        if (segments === 'loading') {
            return;
        }
        setSegments([...segments, segment]);
    };
    const lastSegment = segments === 'loading' ? null : segments.at(-1) ?? null;
    const activeSegment = lastSegment !== null && lastSegment.status === 'recording' ? lastSegment : null;
    return (_jsxs(Page, { children: [_jsx(EventCard, { event: event }), _jsx("div", { className: styles.columns, children: event === 'not-found' ? null : (segments === 'loading' || event === 'loading' ? _jsx(Spin, {}) : (_jsxs(_Fragment, { children: [_jsx(SegmentsCard, { segments: segments }), _jsx(RecordingCard, { event: event, activeSegment: activeSegment, segmentsCount: segments.length, onSegmentStarted: handleSegmentStarted, onSegmentEnded: fetchSegmentsState })] }))) })] }));
};
export default EventPage;
